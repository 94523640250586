.legal {
  font-family: "HelvNeue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  max-width: 768px;
  margin: 0 auto;
  padding: 1rem;
}

.legal > div {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.font-weight-bold {
  font-weight: bold;
}

ul, ol {
  padding: 0 2rem;
}
